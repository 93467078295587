import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import './corporateMainNavigation.scss'

const CorporateMainNavigation = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            logos: globalSets(handle: "logos") {
                ... on Craft_logos_GlobalSet {
                  corporateMainNavigationLogo {
                    url
                    ... on Craft_websiteLogos_Asset {
					     altText
                     }
                  }
                }
              }
            leftMenu: navigationNodes(navHandle: "corporateMainNavigationLeft", level: 1) {
                title
                nodeUri
                id
                children {
                    title
                    nodeUri
                    id
                    children {
                        title
                        nodeUri
                        id
                        children {
                            title
                            nodeUri
                            id
                        }
                    }
                }
            }
            rightMenu: navigationNodes(navHandle: "corporateMainNavigationRight", level: 1) {
                title
                nodeUri
                id
                children {
                    title
                    nodeUri
                    id
                    children {
                        title
                        nodeUri
                        id
                        children {
                            title
                            nodeUri
                            id
                        }
                    }
                }
            }
          }
        }
  `)

    const getAnchorClass = (children) => {
        if (children && children.length > 0) {
            return 'open-menu-level-sub'
        }
    }

    const toggleSubMenu = (event) => {
        if (parseInt(event.target.getAttribute('data-navlevel')) === 0) {
            event.preventDefault();
            Array.from(document.querySelectorAll(".menu > ul > li > a"))
                .filter(element => (
                   element !== event.target
                ))
                .forEach(node => (
                    node.classList.remove("active-menu")
                ))
        }
        if (event.target.classList.contains('open-menu-level-sub')) {
            event.preventDefault();
            event.target.classList.toggle("active-menu")
        }
    }

    const toggleMobileNav = (event) => {
        document.getElementById("mobile-menu").classList.toggle("active")
        event.target.classList.toggle("toggle-active")
    }

    const getUrl = (node) => {
        // Test if empty
        if (node.nodeUri) {
            // Test if absolute URL
            var r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (r.test(node.nodeUri)) {
                return node.nodeUri
            } else {
                return `/${node.nodeUri}`
            }
        } else {
            return '#'
        }
    }

    let tabIndex = 0

    const navWalker = (nodes, navLevel = 0) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        tabIndex += 1

        return nodes.map(node => (
            <li key={node.id}>
                <a tabIndex={tabIndex} href={getUrl(node)} className={getAnchorClass(node.children)} onClick={toggleSubMenu} data-navlevel={navLevel}>{node.title}</a>

                {node.children && node.children.length > 0 ? (
                    <ul className="menu-sub">{ navWalker(node.children, navLevel + 1) }</ul>
                ):(
                    null
                )}
            </li>
        ))
    }

    return (
        <div className="corporate-page-header">
            <div className="container">
                <div className="header-inner">
                    <div className="menu menu-left">
                        <ul>{ navWalker(data.craft.leftMenu) }</ul>
                    </div>
                    <div className="logo"><img src={data.craft.logos[0].corporateMainNavigationLogo[0].url} alt={data.craft.logos[0].corporateMainNavigationLogo[0].altText} /></div>
                    <div className="menu menu-right">
                        <ul>{ navWalker(data.craft.rightMenu) }</ul>
                    </div>

                    <div className="menu-toggle"><a href="#" onClick={toggleMobileNav}></a></div>

                    <div className="menu mobile-menu" id="mobile-menu">
                        <ul>
                            { navWalker(data.craft.leftMenu) }
                            { navWalker(data.craft.rightMenu) }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CorporateMainNavigation