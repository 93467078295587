import * as React from 'react'
import CorporateMainNavigation from "./corporateMainNavigation"
import GlobalAnnouncement from "./globalAnnouncement"
import Footer from "./footer"
import NewsletterSignup from "./newsletterSignup"
import Seo from "./seo"
import './corporateLayout.scss'

const CorporateLayout = ({ seomatic, children, entryId }) => {

    return (
        <div className="wrapper">
            <Seo seomatic={seomatic} />
            <CorporateMainNavigation/>
            <GlobalAnnouncement/>
            {children}
            <NewsletterSignup entryId={entryId} />
            <Footer/>
        </div>
    )

}

export default CorporateLayout