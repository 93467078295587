import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import LargeCarousel from "../global/largeCarousel";
import "./section-1.scss"

const Section1 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "corporateHome") {
              ... on Craft_corporateHome_corporateHome_Entry {
                corporateHomeLargeCarousel {
                  ... on Craft_corporateHomeLargeCarousel_slide_BlockType {
                    image {
                      url(transform: "largeCarouselTransform")
                      ... on Craft_websiteCorporateHomeCarousel_Asset {
					     altText
                      }
                    }
                    imageLink {
                      linkUrl
                      openInNewTab
                    }
                  }
                }
              }
            }
          }
        }
  `)

    return (
        <LargeCarousel slides={data.craft.entry.corporateHomeLargeCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>
    )
}

export default Section1