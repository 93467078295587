import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import parse from "html-react-parser";
import "./section-2.scss"

const Section2 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "corporateHome") {
                ... on Craft_corporateHome_corporateHome_Entry {
                    corporateHomeSection2ContentTitleWithMarks
                    section2TextContent
                }
            }
          }
        }
  `)

    return (
        <div className="life-one">
            <div className="container-small">
                {parse(data.craft.entry.corporateHomeSection2ContentTitleWithMarks)}
                {parse(data.craft.entry.section2TextContent)}
            </div>
        </div>
    )
}

export default Section2