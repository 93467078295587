import * as React from "react"
import {graphql, useStaticQuery} from "gatsby";
import CorporateLayout from "../components/global/corporateLayout"
import Section1 from "../components/corporate-home/section-1"
import Section2 from "../components/corporate-home/section-2"
import Section3 from "../components/corporate-home/section-3"
import Section5 from "../components/corporate-home/section-5"

const IndexPage = () => {

    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "corporateHome") {
              ... on Craft_corporateHome_corporateHome_Entry {
                id
                seomatic {
                  metaTitleContainer
                  metaTagContainer
                  metaLinkContainer
                  metaScriptContainer
                  metaJsonLdContainer
                  metaSiteVarsContainer
                  frontendTemplateContainer
                }
              }
            }
          }
        }
  `)

    return (
        <CorporateLayout seomatic={data.craft.entry.seomatic} entryId={data.craft.entry.id}>
            <Section1></Section1>
            <Section2></Section2>
            <Section3></Section3>
            <Section5></Section5>
        </CorporateLayout>
    )
}

export default IndexPage
