import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import Button from "../global/button"
import ImageLink from "../global/imageLink"
import parse from "html-react-parser";
import "./section-5.scss"

const Section5 = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            entry(section: "corporateHome") {
                ... on Craft_corporateHome_corporateHome_Entry {
                    section5ContentTitle
                    section5TextContentWithLink
                    section5ButtonLink {
                        linkUrl
                        openInNewTab
                        linkText
                    }
                    section5ImageLink {
                        linkUrl
                        openInNewTab
                    }
                    section5ImageLeft
                    corporateHomeSection5Image {
                        url(transform: "mediumHorizontal32AspectRatioTransform")
                        ... on Craft_websiteCorporateHome_Asset {
                            altText
                          }
                    }
                }
            }
          }
        }
  `)

    const getContent = () => {
        return (
            <>
                <h3>{data.craft.entry.section5ContentTitle}</h3>
                {parse(data.craft.entry.section5TextContentWithLink)}
                <Button classes="btn-primary" to={data.craft.entry.section5ButtonLink[0].linkUrl} text={data.craft.entry.section5ButtonLink[0].linkText} openInNewTab={data.craft.entry.section5ButtonLink[0].openInNewTab}></Button>
            </>
        )
    }

    const getImage = () => {
        return (
            <ImageLink src={data.craft.entry.corporateHomeSection5Image[0].url} alt={data.craft.entry.corporateHomeSection5Image[0].altText} to={data.craft.entry.section5ImageLink[0].linkUrl} openInNewTab={data.craft.entry.section5ImageLink[0].openInNewTab}></ImageLink>
        )
    }

    return (
        <div className="pet-food">
            <div className="container-small">

                <div className="post-block">
                    <div className="post-block-left">
                        {data.craft.entry.section5ImageLeft === true ? (
                            getImage()
                        ):(
                            getContent()
                        )}
                    </div>
                    <div className="post-block-right">
                        {data.craft.entry.section5ImageLeft === true ? (
                            getContent()
                        ):(
                            getImage()
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section5